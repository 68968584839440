<template>
  <div class="row">
    <div class="flex md12">
      <va-form>
        <va-select
          searchable
          class="mb-4"
          label="Operator"
          v-model="form.operator"
          :options="operators"
          :track-by="(option) => option.id"
          text-by="name"
          value-by="id"
          v-if="canCreateToursForAllOperators"
        />
        <va-input
          class="mb-4"
          v-model="form.title"
          label="Title"
          placeholder="Title"
          :error="v$.form.title.$error"
          :error-messages="v$.form.title.$error ? v$.form.title.$errors[0].$message: ''"/>
        <va-input
          class="mb-4"
          v-model="form.adventureType"
          label="Type d'aventure (ex: Randonnée, Culture, etc)"
          placeholder="Type d'aventure"
          :error="v$.form.adventureType.$error"
          :error-messages="v$.form.adventureType.$error ? v$.form.adventureType.$errors[0].$message: ''"/>
        <va-input
          class="mb-4"
          type="number"
          v-model="form.maxCapacity"
          label="Capacité maximum"
          placeholder="Capacité maximum"
        />
        <slot/>
      </va-form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useStore} from "vuex"

export default {
  name: "ProgramCreateGeneral",
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  setup () {
    const store = useStore()
    return {
      v$: useVuelidate({$autoDirty: true}),
      canCreateToursForAllOperators: store.getters['canCreateToursForAllOperators']
    }
  },
  data () {
    return {
      form: this.modelValue,
      operator: 1,
      operators: []
    }
  },
  validations () {
    return {
      form: {
        title: { required },
        adventureType: { required }
      }
    }
  },
  async created() {
    const data = await this.axios.get('/api/operators/')
    this.operators = data.data
  },
}
</script>

<style scoped>

</style>
