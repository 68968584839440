<template>
  <div class="row" v-if="isSuperAdmin">
    <div class="flex md1"></div>
    <div class="flex md10">
      <va-card>
        <va-card-content>
          <va-input
            class="mb-4"
            v-model="linkToCrawl"
            label="Website link to crawl"
            placeholder="Website link to crawl"
          />
          <va-button @click="crawlLink" :loading="loadingCrawl">
            Crawl
          </va-button>
        </va-card-content>
      </va-card>
    </div>
  </div>
  <div class="row">
    <div class="flex md1"></div>
    <div class="flex md10">
      <va-card>
        <va-card-content>
          <va-tabs v-model="mainTabsValue" grow>
            <template #tabs>
              <va-tab v-for="tab in mainTabs" :name="tab.title" :key="tab.title">
                {{ tab.title }}
              </va-tab>
            </template>
            <div class="row row-equal mt-3">
              <div class="flex md12">
                <div v-if="currentMainMenuTab.title === 'General'">
                  <ProgramCreateGeneral v-model="formGeneral">
                    <va-file-upload v-model="tourPictures" type="gallery" file-types="image/*" dropzone/>
                  </ProgramCreateGeneral>
                </div>
                <div v-if="currentMainMenuTab.title === 'Détails'">
                  <ProgramCreateDetails v-model="formDetails"/>
                </div>
                <div v-else-if="currentMainMenuTab.title === 'Programme Journalier'">
                  <ProgramCreateDaily v-model="formDaily" :country-options="countryOptions"/>
                </div>
                <div v-else-if="currentMainMenuTab.title === 'Dates et Tarifs'">
                  <ProgramCreateDates v-model="formDates"/>
                </div>
                <div v-else-if="currentMainMenuTab.title === 'Confirmation'">
                  <ProgramCreateConfirmation
                    :formGeneral="formGeneral"
                    :formDetails="formDetails"
                    :formDaily="formDaily"
                    :formDates="formDates"
                    :errors="errors">
                    <va-button size="large" gradient @click="submitNewTour" :loading="loadingForm">Confirmer Programme</va-button>
                  </ProgramCreateConfirmation>
                </div>
              </div>
            </div>
          </va-tabs>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import ProgramCreateGeneral from './ProgramCreateGeneral.vue'
import ProgramCreateDetails from './ProgramCreateDetails.vue'
import ProgramCreateDaily from './ProgramCreateDaily.vue'
import ProgramCreateDates from './ProgramCreateDates.vue'
import ProgramCreateConfirmation from './ProgramCreateConfirmation.vue'
import { mapGetters } from 'vuex'
import { useGlobalConfig } from 'vuestic-ui'
import dayjs from "dayjs";

export default {
  name: "ProgramCreate",
  components: {
    ProgramCreateGeneral,
    ProgramCreateDetails,
    ProgramCreateDaily,
    ProgramCreateDates,
    ProgramCreateConfirmation
  },
  data() {
    return {
      linkToCrawl: '',
      loadingCrawl: false,
      countryOptions: [],
      tourPictures: [],
      formGeneral: {
        title: '',
        adventureType: 'Découverte',
        operator: 1,
        maxCapacity: -1,
      },
      formDetails: {
        description: '',
        inclusionText: '',
        exclusionText: '',
        reservationDetails: '',
      },
      formDaily: {
        days: [
          { id: '1', title: '', description: '', cities: [{id: 0, time: null, city: '', location: '', type: 'departure'}]},
          { id: '2', title: '', description: '', cities: []},
          { id: '3', title: '', description: '', cities: []}
        ],
      },
      formDates: {
        detailedDates: [],
        baseAdultPrice: null,
        baseChildrenPrice: null,
        baseInfantPrice: null,
        maxChildrenAge: 12,
        maxInfantAge: 2,
        supplements: [],
      },
      mainTabs: [
        {
          title: "General",
        },
        {
          title: "Détails",
        },
        {
          title: "Programme Journalier",
        },
        {
          title: "Dates et Tarifs",
        },
        {
          title: "Confirmation",
        },
      ],
      loadingForm: false,
      mainTabsValue: "General",
      errors: [],
    }
  },
  computed: {
    currentMainMenuTab () {
      return this.mainTabs.find(({ title }) => title === this.mainTabsValue)
    },
    crawlerUrl() {
      return useGlobalConfig().getGlobalConfig().crawlerUrl
    },
    ...mapGetters({
      isSuperAdmin: 'isSuperAdmin',
    }),
  },
  async created () {
    const countries = await this.axios.get('/api/countries')
    this.countryOptions = countries.data
  },
  methods: {
    async submitNewTour() {
      try {
        this.loadingForm = true
        this.formDaily.days.map(day => {
          return {
            ...day,
            cities: day.cities.map(city => {
              return {
                ...city,
                cityId: city.id,
                time: city.time ? city.time.toISOString() : null
              }
            })
          }
        })
        const formDatesCopy = JSON.parse(JSON.stringify(this.formDates))
        formDatesCopy.detailedDates = this.formDates.detailedDates.map(date => {
          return {
            ...date,
            date: dayjs(date.date).format('YYYY-MM-DD'),
          }
        })
        const validForm = {
          ...this.formGeneral,
          ...this.formDetails,
          ...this.formDaily,
          ...formDatesCopy,
        }
        const formData = new FormData()
        if (this.tourPictures.length == 0) {
          alert('Veuillez ajouter au moins une photo')
          return
        }
        for (const picture of this.tourPictures) {
          formData.append('pictures[]', picture)
        }
        const pictureResponse = await this.axios.post('/api/picture', formData)
        validForm.pictures = pictureResponse.data
        await this.axios.post('/api/tours', validForm)
        this.errors = []
        alert('Programme créé avec succès')
      } catch (e) {
        if (e?.response?.status === 422) {
          this.errors = Object.keys(e.response.data.errors).map(key => e.response.data.errors[key]).flat()
        } else {
          console.log(e)
          this.errors = ['An error occurred']
        }
      } finally {
        this.loadingForm = false
      }
    },
    async crawlLink() {
      try {
        this.loadingCrawl = true
        const response = await this.axios.post(`${this.crawlerUrl}/crawl`, {website: this.linkToCrawl})
        const responseData = response.data
        if (responseData.title !== 'Not available') {
          this.formGeneral.title = responseData.title
        }
        if (responseData.description !== 'Not available') {
          this.formDetails.description = responseData.description
        }
        if (responseData.inclusionText !== 'Not available') {
          this.formDetails.inclusionText = this.handleLineBreaks(responseData.inclusionText)
        }
        if (responseData.exclusionText !== 'Not available') {
          this.formDetails.exclusionText = this.handleLineBreaks(responseData.exclusionText)
        }
        if (responseData.reservationDetails !== 'Not available') {
          this.formDetails.reservationDetails = responseData.reservationDetails
        }
        if (responseData.days !== 'Not available') {
          this.formDaily.days = responseData.days.map((day, index) => {
            return {
              id: (index + 1).toString(),
              title: day.title,
              description: day.description,
              cities: []
            }
          })
        }
        if (responseData.price !== 'Not available') {
          this.formDates.baseAdultPrice = responseData.price
        }
        if (responseData.tripsDates !== 'Not available') {
          this.formDates.detailedDates = responseData.tripsDates.map((date, index) => {
            return {
              id: (index + 1).toString(),
              date: new Date(date),
              adultPrice: responseData.price,
              childPrice: null,
              infantPrice: null
            }
          })
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingCrawl = false
      }
    },
    handleLineBreaks (text) {
      // keep only one line break if there are many
      text = text.replace(/(\r\n|\n|\r){2,}/gm, '\n')
      // Remove sentences "Votre voyage inclut" and "Votre voyage n'inclut pas"
      text = text.replace(/Votre voyage inclut\n/gm, '')
      text = text.replace(/Votre voyage n'inclut pas\n/gm, '')
      return text
    },
  }
}
</script>

<style scoped>

</style>
