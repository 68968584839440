<template>
  <div class="row">
    <div class="row justify--center" v-if="errors.length !== 0">
      <div class="flex lg8">
        <va-alert color="danger" icon="info" class="mb-1">
          <ul>
            <li v-for="(error, index) in errors" :key="index">
              {{ error }}
            </li>
          </ul>
        </va-alert>
      </div>
    </div>
    <br>
    <div class="flex md12" style="font-size: 20px">
      <div class="row">
        <div class="flex md12">
          <p>
            Résumé de votre programme. Veuillez vérifier que toutes les informations sont correctes. <br>
            Pour valider votre programme, cliquez sur le bouton "Confirmer Programme" en bas de la page.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="flex md12">
          <p><span class="text--bold mr-2">Titre:</span>{{ formGeneral.title }}</p>
          <p><span class="text--bold mr-2">Type d'Aventure:</span> {{ formGeneral.adventureType }}</p>
          <p><span class="text--bold mr-2">Région:</span> {{ formGeneral.region }}</p>
          <p><span class="text--bold mr-2">Capacité Maximum:</span> {{ formGeneral.maxCapacity }}</p>
        </div>
      </div>
      <div class="row">
        <div class="flex md12">
          <div class="row">
            <div class="flex md12">
              <div class="text-block">
                <p class="display-5">Description</p>
                <span>{{ formDetails.description }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="flex md12">
              <div class="text-block">
                <p class="display-5">Inclusions</p>
                <span>{{ formDetails.inclusionText }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="flex md12">
              <div class="text-block">
                <p class="display-5">Exclusions</p>
                <span>{{ formDetails.exclusionText }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="flex md12">
              <div class="text-block">
                <p class="display-5">Détails de réservation</p>
                <span>{{ formDetails.reservationDetails }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="flex md12">
          <va-accordion v-model="value" multiply>
            <va-collapse v-for="(day, index) in formDaily.days" :key="index" :header="`Jour ${index+1}: ${day.title}`">
              <div class="text-block">
                {{ day.description }}
                <ul class="va-unordered">
                  <li v-for="(city, index) in day.cities" :key="index">
                    {{ city.type }}: {{ city.city.name }} - {{ city.location }} - {{ formatTime(city.time) }}
                  </li>
                </ul>
              </div>
            </va-collapse>
          </va-accordion>
        </div>
      </div>
      <div class="row">
        <div class="flex md12">
          <va-data-table :items="formDates.detailedDates" :columns="columns" hoverable>
            <template #cell(date)="{ value }">{{ formatDate(value) }}</template>
          </va-data-table>
        </div>
      </div>
    </div>
    <div class="row justify--end">
      <div class="flex lg12">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "ProgramCreateConfirmation",
  props: {
    formGeneral: {
      type: Object,
      required: true
    },
    formDetails: {
      type: Object,
      required: true
    },
    formDaily: {
      type: Object,
      required: true
    },
    formDates: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      value: Array(this.formDaily.days.length).fill(false),
      columns: [
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'adultPrice',
          label: 'Prix Adulte (Dhs)',
        },
        {
          key: 'childPrice',
          label: 'Prix Enfant (Dhs)',
        },
        {
          key: 'infantPrice',
          label: 'Prix Bébé (Dhs)',
        },
      ]
    }
  },
  computed: {
    formatDate () {
      return (value) => {
        if (value) {
          return dayjs(value).format("DD/MM/YYYY");
        }
      }
    },
  },
  methods: {
    formatTime (time) {
      return dayjs(time).format('HH:mm')
    },
  }
}
</script>

<style scoped>

</style>
