<template>
  <div class="row">
    <div class="flex md12">
      <h6 class="display-6 mb-3">Description du Voyage</h6>
      <va-input
        class="mb-4"
        v-model="form.description"
        type="textarea"
        label="Description"
        placeholder="Décrivez votre voyage organisé. Vendez du rêve !"
        :min-rows="5"
        :error="v$.form.description.$error"
        :error-messages="v$.form.description.$error ? v$.form.description.$errors[0].$message: ''"/>
      <h6 class="display-6 mb-3">Le Voyage Inclus</h6>
      <va-input
        class="mb-4"
        v-model="form.inclusionText"
        type="textarea"
        label="Inclus"
        :min-rows="5"
        placeholder="Listez ici tout ce que votre voyage inclus (sur plusieurs lignes)
eg:
Petit Déjeuner
Animation
Hébergement"/>
      <h6 class="display-6 mb-3">Le Voyage N'Inclus pas</h6>
      <va-input
        class="mb-4"
        v-model="form.exclusionText"
        type="textarea"
        label="Non Inclus"
        :min-rows="5"
        placeholder="Listez ici tout ce que votre voyage n'inclus pas (sur plusieurs lignes)
eg:
Billet d'avion
Visa
Déjeuners et Diners
"/>
      <h6 class="display-6 mb-3">Détails additionnels</h6>
      <va-input class="mb-4" v-model="form.reservationDetails" type="textarea" label="Détails de réservation" placeholder="Détails de réservation"/>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: "ProgramCreateDetails",
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  setup() {
    return { v$: useVuelidate({ $autoDirty: true }) }
  },
  data () {
    return {
      form: this.modelValue,
    }
  },
  validations () {
    return {
      form: {
        description: { required },
      }
    }
  },
}
</script>

<style scoped>

</style>
